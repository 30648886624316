import logo from "./logo.svg";
import "./App.css";
import Home from "./views/Home";
import Header from "./components/Header";
import RewardCenter from "./views/RewardCenter";
import React, { useEffect, useMemo, useState } from "react";
import FirebaseContextProvider from "./firebase";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { UnsafeBurnerWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./sections/Footer";
import { getNFTs } from "./Lib/Helius";

function App() {
  const [collectionData, setCollectionData] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [localWallet, setLocalWallet] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
    [network]
  );

  /** Local Storage Caching */
  useEffect(() => {
    let pullLocalWallet = JSON.parse(localStorage.getItem("userWallet"));
    setLocalWallet(pullLocalWallet);
  }, []);

  const getResponse = async (wallet) => {
    const resNFT = await getNFTs(wallet);
    console.log("resNFT", resNFT);
    let nfts = resNFT.nfts;
    let filteredNFTs = nfts.filter((nft) =>
      // return only nfts who's name contains the word money
      nft.name.toLowerCase().includes("money")
    );
    console.log("filteredNFTs", filteredNFTs);
    setNfts(filteredNFTs);
    setIsUpdated(true);
  };

  /** Get Assets */
  useEffect(() => {
    if (localWallet) {
      let wallet = localWallet;
      // GET DATA FROM HELIUS //
      (async () => getResponse(wallet))();

      // let resBalances = await getBalances(wallet);
      // // let resTransactions = await getTransactions(wallet)

      // // GET PRICES, METADATA AND TOTAL BALANCE FOR SPL TOKENS //
      // let prices = await getPrices(
      //   await addTokenMetadata(
      //     tokenList,
      //     resBalances.tokens,
      //     resBalances.nativeBalance
      //   )
      // );
      // let tokens = prices.coins;
      // let totalBalance = prices.totalBalance;

      // setBalances(tokens);
      // setTotalBalance(totalBalance);

      // setTransactions(resTransactions)
    }
  }, [localWallet]);

  return (
    <div className="App">
      <FirebaseContextProvider>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <div className="font-jakarta bg-black">
                {" "}
                <Router>
                  <Header setLocalWallet={setLocalWallet} />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                      isUpdated={isUpdated}
                      path="/reward-center"
                      element={<RewardCenter nfts={nfts} />}
                    ></Route>
                  </Routes>
                  {/* <div className="h-40 -mb-10 w-full bg-black"> </div> */}
                  <Footer />
                </Router>
              </div>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </FirebaseContextProvider>
    </div>
  );
}

export default App;
