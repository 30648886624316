import React from "react";
const navigation = {
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/moneyboysNFT",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};
export default function Footer() {
  return (
    <footer className="bg-black w-full" aria-labelledby="footer-heading z-40">
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-10 sm:pt-24 lg:px-8 lg:pt-30">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <p className="text-3xl font-bold text-white text-left">
              {" "}
              moneyboys
            </p>

            <p className="text-sm flex text-left  text-gray-100">
              Made with <p className="mx-2 text-pink-300"> ❤</p> in California
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-100"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="mb-20 -mt-10 flex justify-end items-center w-full text-right">
          <p className="text-xs  text-right leading-5 text-gray-300">
            &copy; 2023 MoneyBoys, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
