import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";

import flower from "../assets/images/Nature_Pattern_and_Elements/Nature_Elements/Floral-1.png";
import flower2 from "../assets/images/Nature_Pattern_and_Elements/Nature_Elements/Floral-5.png";
import flower3 from "../assets/images/Nature_Pattern_and_Elements/Nature_Elements/Floral-6.png";
import moneyboy from "../assets/images/MONEYBOY2.png";

let fakeData = [
  {
    id: 1,
    title: "MONEYBOY2",
    img: "https://i.ibb.co/7QpKsCX/image.png",
    price: 100,
  },
  {
    id: 1,
    title: "MONEYBOY2",
    img: "https://i.ibb.co/7QpKsCX/image.png",
    price: 100,
  },
  {
    id: 1,
    title: "MONEYBOY2",
    img: "https://i.ibb.co/7QpKsCX/image.png",
    price: 100,
  },
  {
    id: 1,
    title: "MONEYBOY2",
    img: "https://i.ibb.co/7QpKsCX/image.png",
    price: 100,
  },
  {
    id: 1,
    title: "MONEYBOY2",
    img: "https://i.ibb.co/7QpKsCX/image.png",
    price: 100,
  },
];

export default function Listings(props) {
  const [listings, setListings] = React.useState(props.listings);
  const [stats, setStats] = React.useState(props.stats);

  const shiftListings = () => {
    // add first four items from listings to the end of the array
    let newListing = listings.slice(0, 4);
    let updatedListings = listings.slice(4, listings.length);
    updatedListings = updatedListings.concat(newListing);
    setListings(updatedListings);
  };

  useEffect(() => {
    setListings(props.listings);
    setStats(props.stats);
  }, [props.listings]);

  return (
    <div className="flex w-full justify-center flex-col items-center px-7 bg-white pt-5 pb-20  z-50 backdrop-blur-lg overflow-hidden ">
      <div className="flex justify-around items-center w-full my-5">
        <p className="py-12 text-2xl font-medium text-gray-800">
          {" "}
          Join and stake for future $PAINT.
        </p>
      </div>
      <div className="flex justify-around items-center w-full mx-3 mt-12 ">
        <div className="flex text-gray-400 space-x-2 divide-x divide-gray-300 text-sm invisible md:visible  w-full mx-4 mb-5 items-center">
          <p className="font-medium  "> AVAILABLE PIECES</p>
          <p className="px-2"> moneyboys OG collection </p>
        </div>
        <div className="flex  text-gray-400 space-x-2   divide-gray-300 divide-x text-sm  w-0 invisible md:visible md:w-full mx-4 mb-5 items-center">
          <div className="flex items-center space-x-2">
            <p className="font-medium  "> FLOOR PRICE: </p>
            <p className="pr-3">
              {props.stats && (props.stats.floorPrice / 1000000000).toFixed(2)}{" "}
              SOL
            </p>
          </div>
          <div className="flex items-center space-x-2 ">
            <p className="font-medium pl-4  "> PIECES LISTED: </p>
            <p className="">{props.stats && props.stats.listedCount}</p>
          </div>
        </div>
        <div className="flex  text-black mx-8 mb-5 justify-end items-center space-x-2 w-0 invisible md:visible md:w-full">
          <a
            target="_blank"
            href="https://magiceden.io/marketplace/solana_money_boys"
          >
            <button className="flex items-center ">
              <div className="flex items-center space-x-2">
                <p className="text-sm "> See All </p>
                <ArrowRightCircleIcon className="h-5 w-5" />
              </div>
            </button>
          </a>
        </div>
      </div>
      <div className="grid grid-col-2 sm:grid-cols-5 rows-2 gap-0.5  col-span-10 w-full space-x-auto  justify-start rounded overflow-hidden ">
        {listings.length > 0 &&
          listings.map((item, index) => (
            <div
              key={index + 1}
              className="flex flex-col col-1 h-full text-center p-12 bg-[#EDEEE0] shadow-inner overflow-hidden  "
            >
              <a
                href={"https://magiceden.io/item-details/" + item.tokenMint}
                target="_blank"
              >
                <div className="flex  flex-col  overflow-hidden  hover:scale-105 hover:shadow-xl  transition duration-500 transform  p-4 bg-white backdrop-blur-sm  shadow">
                  <img
                    src={item.extra.img}
                    alt="MONEYBOY2"
                    className="shadow-inner"
                  />
                </div>
                <div className="pt-5 -mb-5 font-futura  font-extralight text-sm text-yellow-900/80 z-40">
                  {" "}
                  {item.price.toFixed(2)} SOL
                </div>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
}
