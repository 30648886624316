import React from "react";
import flower from "../assets/images/Nature_Pattern_and_Elements/Nature_Elements/Floral-8.png";
import flower2 from "../assets/images/Nature_Pattern_and_Elements/Nature_Elements/Floral-12.png";

export default function RewardCenter(props) {
  return (
    <div className="flex  justify-center w-full  bg-black  text-black border-y border-[#A37F4B]  ">
      <div className=" w-full flex flex-col px-10 pt-10 h-128 items-center bg-[#EDEEE0] overflow-x-hidden ">
        <div className="flex w-full px-10 ">
          <p className="text-[#A37F4B] px-16 pb-5  font-bold text-4xl text-left">
            {" "}
            Collection
          </p>
        </div>
        {props.nfts.length < 1 && (
          <div className="  flex w-full flex-col text-left overflow-hidden ">
            <div className="  flex w-full px-10 text-left ">
              <p className="text-black px-16 pb-5 font-bold text-left">
                {" "}
                Connect a Wallet with official moneyboy NFTs
              </p>
            </div>
            <img
              src={flower}
              className="flex mx-10 justify-center w-96 object-contain"
            />
          </div>
        )}

        <div className="grid grid-cols-2  ">
          {" "}
          {props.nfts &&
            props.nfts.map((nft, index) => {
              return (
                <div className="flex  m-5   h-56 w-56 flex-col items-center justify-center space-y-2 max-w-sm   bg-black p-10  backdrop-blur bg-white/40   overflow-hidden shadow-2xl  hover:scale-105 hover:shadow-3xl  transition duration-500 transform">
                  <img src={nft.imageUrl} className=" object-contain" />
                  {/* <div className="text-[#A37F4B] font-bold text-2xl">
                    {nft.name}
                  </div>
                  <div className="text-[#A37F4B]  text-sm">
                    {" "}
                    {nft.description}
                  </div> */}
                </div>
              );
            })}{" "}
        </div>
      </div>
      <div className=" flex w-full h-full shadow-2xl">
        <div className=" flex w-full justify-start flex-col bg-black  border-l border-[#A37F4B]  ">
          <div className="flex w-full bg-yellow-100/5  items-center text-left justify-between  border-b  border-[#A37F4B]  px-10 py-12  ">
            <div className="flex flex-col justify-center space-y-2 ">
              <div className="text-yellow-400 font-bold text-2xl">
                {" "}
                My Rewards
              </div>
              <div className="text-white  text-sm">
                {" "}
                All your rewards in one place.
              </div>
            </div>
            <div className="flex flex-col justify-center space-y-2">
              {" "}
              <div className="text-white font-bold text-4xl text-right">
                {" "}
                {props.nfts && props.nfts.length}{" "}
              </div>
              <div className="text-white  text-sm"> Official MoneyBoy NFTs</div>{" "}
            </div>
          </div>
          <div className="flex w-full bg-indigo-100/10 items-center text-left justify-between  border-b  border-[#A37F4B]  space-y-2 px-10 py-12  ">
            <div className="flex flex-col space-y-2 ">
              <div className="text-indigo-400 font-bold text-2xl">
                {" "}
                $PAINT Earnings
              </div>
              <div className="text-white  text-sm">
                {" "}
                Claim your combined $paint earnings for your entire collection.
              </div>
            </div>
            <div>
              {" "}
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-500 px-5 py-2 text-md font-medium text-white  shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Claim $PAINT
              </button>
            </div>
          </div>{" "}
          <div className="flex w-full bg-green-100/10 items-center text-left justify-between  border-b  border-[#A37F4B]  space-y-2 px-10 py-12  ">
            <div className="flex flex-col space-y-2 max-w-sm">
              <div className="text-green-400 font-bold text-2xl">
                {" "}
                MoneyVault Prizes
              </div>
              <div className="text-white  text-sm">
                {" "}
                Prizes from the moneyvault.
              </div>
            </div>
            <div>
              {" "}
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-green-500 px-5 py-2 text-md font-medium text-white  shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Claim Prize
              </button>
            </div>
          </div>{" "}
          <div className="flex w-full bg-red-100/10  items-center text-left justify-between h-full  space-y-2 px-10 py-12  ">
            <div className="flex flex-col space-y-2 max-w-sm">
              <div className="text-red-400 font-bold text-2xl"> Allow List</div>
              <div className="text-white  text-sm">
                {" "}
                Exclusive minting opportunities for moneyboys.
              </div>
            </div>
            <div>
              {" "}
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-red-500 px-5 py-2 text-md font-medium text-white  shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Claim WL
              </button>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
