// Import the functions you need from the SDKs you need
import { createContext, useContext } from "react";
import { getFunctions } from "firebase/functions";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBgNL6dIe4NANH7TWJg3ptV9ILuHsBo-z4",
  authDomain: "moneysite-63f38.firebaseapp.com",
  projectId: "moneysite-63f38",
  storageBucket: "moneysite-63f38.appspot.com",
  messagingSenderId: "806968143188",
  appId: "1:806968143188:web:86d79b833474a1263b6cc1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const FirebaseContext = createContext(null);
export const useFirebaseContext = () => useContext(FirebaseContext);

const FirebaseContextProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider
      value={{
        functions: getFunctions(app),
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContextProvider;
