import React from "react";
import MONEYBOY2 from "../assets/images/MONEYBOY2.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import Listings from "../sections/Listings";
import Socials from "../sections/Socials";
import Story from "../sections/Story";
import { CurrencyDollarIcon } from "@heroicons/react/20/solid";
import flower from "../assets/images/Nature_Pattern_and_Elements/Nature_Elements/Floral-7.png";
import flower2 from "../assets/images/Nature_Pattern_and_Elements/Nature_Elements/Floral-5.png";
import flower3 from "../assets/images/Nature_Pattern_and_Elements/Nature_Elements/Floral-6.png";
import flower5 from "../assets/images/WormholePink.png";

import main from "../assets/images/boy2.png";
import moneyboys2 from "../assets/images/moneyboy2.0.png";
import VideoLooper from "react-video-looper";
import video from "../assets/images/pesto.mp4";
import nico from "../assets/images/nico.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 200 })),
      borderColor: "black",
      backgroundColor: "black",
    },
  ],
};

export default function Home() {
  const [listings, setListings] = useState([]);
  const [stats, setStats] = useState({});

  useEffect(() => {
    const functions = getFunctions();
    const getCollectionData = httpsCallable(functions, "getCollectionAllData");
    getCollectionData("solana_money_boys").then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      const data = result.data;
      console.log(data);
      if (data && data.listing) {
        // sort listings by price
        data.listing.sort((a, b) => a.price - b.price);
        setListings(data.listing);
        setStats(data.stats);
      }
    });
  }, []);

  return (
    <div className="flex flex-col font-jakarta items-center  bg-blue-200 overflow-hidden ">
      <div className="flex flex-col space-y-10 bg-[#EDEEE0] items-center bg-right-bottom	shadow-inner justify-center w-full pt-5 h-page overflow-hidden">
        <div className="flex justify-between overflow-hidden h-0  ">
          <img
            src={flower}
            className="md:absolute flex top-20 justify-center items-center w-full  max-w-xs bottom-10  object-contain -right-0 xl:z-40 lg:z-30 invisible md:visible"
          />
          <img
            src={flower3}
            className="md:absolute flex top-60 justify-center  items-center w-full max-w-sm object-contain z-0   invisible md:visible"
          />
          <img
            src={flower2}
            className="md:absolute flex justify-center items-center w-full  max-w-sm bottom-10   object-contain xl:z-40 lg:z-30 -left-0  invisible md:visible  "
          />
        </div>
        <div className="flex w-full flex-col justify-center items-center space-y-2 z-30">
          <img src={moneyboys2} className="w-64 object-contain" />
          <p className="font-light text-sm font-futura"> COMING SOON</p>
        </div>
        <div className="flex items-center justify-center w-full h-full max-w-5xl pb-10  mx-auto z-10 space-x-2">
          <div className="flex space-y-6 flex-col w-full justify-center h-full items-center ">
            <div className="max-w-sm items-end justify-center flex  bg-black p-10  backdrop-blur bg-white/40   overflow-hidden shadow-2xl  hover:scale-105 hover:shadow-3xl  transition duration-500 transform ">
              <img
                src={main}
                alt="MONEYBOY2"
                className=" w-full object-contain  shadow-inner border-white  "
              />
            </div>
          </div>
          <div className="flex space-y-6 flex-col w-full justify-center h-full items-center  ">
            <div className="max-w-sm  items-end justify-center flex  bg-black p-10  backdrop-blur bg-white/40  overflow-hidden shadow-2xl hover:scale-105 hover:shadow-3xl  transition duration-500 transform  ">
              <img
                src={MONEYBOY2}
                alt="MONEYBOY2"
                className="object-cover w-full shadow-inner "
              />
            </div>
          </div>
        </div>
        <div className="flex relative flex-col  items-center w-full  pt-36 pb-40 justify-center bg-black z-40">
          {" "}
          <img
            src={flower5}
            className="absolute flex justify-center top-20 items-center w-full max-w-lg  object-contain  z-10  "
          />
          <div className="flex flex-col pb-16 items-center z-20 space-y-8">
            <p className="text-white text-sm">COMING SOON</p>
            <p className="text-white text-5xl font-bold font-futura">
              {" "}
              the MODA
            </p>

            <p className="font-futura text-lg  font-light text-white max-w-lg">
              {" "}
              [ the museum of digital art ]{" "}
            </p>
            <p className="font-futura text-xl italic text-light text-white max-w-2xl">
              {" "}
              Collaborating with famous 1:1 artists and brands to launch
              seasonal boutique mini-collections with a unique physical
              component. Made possible with $PAINT.
            </p>
          </div>
          <div className="flex mt-10 mx-10 h-96 w-full justify-center items-center ">
            <div className=" font-light space-y-2  w-full  max-w-md flex flex-col items-center ">
              {" "}
              <img
                src={nico}
                className="w-56  min-w-fit h-56 object-contain mb-7 z-20 border-8 shadow-inner border-[#c61f1f]"
              />
            </div>

            <p className="text-8xl text-white  z-30 -mt-4 hover:animate-pulse invisible md:visible">
              {" "}
              +{" "}
            </p>
            <div className="flex w-full ml-2 max-w-md h-full ">
              <VideoLooper
                className="mx-0"
                width={"90%"}
                height={"100%"}
                source={video}
                start={0.05}
                end={5.58}
              />
            </div>
          </div>
          <div className="flex relative w-full flex-col pt-24 items-center z-20 ">
            <img
              src={flower5}
              className="absolute flex justify-center top-20 items-center w-full max-w-xl object-contain  animate-pulse  "
            />
            <p className="font-futura text italic text-light text-gray-200 max-w-sm">
              {" "}
              Note: art above is a proof of concept, official collaborations
              will be announced at a later date.
            </p>
          </div>
        </div>
      </div>
      <Listings listings={listings} stats={stats} />
    </div>
  );
}

{
  /* <div className="max-w-lg flex flex-col h-full space-y-5 mt-4 justify-center backdrop-blur shadow-inner bg-white p-20 border border-black">
<p className="text-4xl font-extrabold text-[#A37F4B] text-left ">
  {" "}
  moneyboys 2.0
</p>

<div className=" h-full w-full text-xs flex items-center rounded-full ">
  <div className="text-xl w-full  flex text-left   space-x-2 text-[#362918] ">
    <p> Creating the best art community on Solana</p>
  </div>
</div>
<button
  type="button"
  className="inline-flex my-2  w-48 text-base shadow-md justify-center items-center font-medium rounded-md border-transparent bg-black/90 px-4 py-2  text-white  hover:bg-black focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
>
  {/* <CurrencyDollarIcon
    className="-ml-1 mr-3 h-5 w-5"
    aria-hidden="true"
  /> */
}
//   EARN $PAINT
// </button>
// </div> */}

{
  /* socials */
}
{
  /* <Socials /> */
}
{
  /* story */
}
{
  /* <Story /> */
}
{
  /* <div class="grid grid-cols-3 py-1 gap-4">
                <button
                  type="button"
                  className="inline-flex justify-center border-gray-300 font-medium items-center rounded border  bg-white px-2.5 py-1.5 text-xs  text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Join
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Twitter
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center  rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Discord
                </button>
              </div> */
}
{
  /* <div className=" flex flex-col w-full border-gray-300 items-start justify-center  divide-gray-400   bg-white shadow-lg  rounded-lg">
                <div className="items-center f px-6 pt-2 w-full h-full flex text-sm font-bold text-gray-700 mx-1  space-x-2 divide-gray-100  ">
                  {" "}
                  <div className="flex h-full flex-col w-full items-start space-y-1 py-3 px-3">
                    {" "}
                    <p className="text-xs text-gray-600 font-light"> Floor </p>
                    <p className="text-lg">3.42 ⊚</p>
                  </div>
                  <div className="flex h-full flex-col  w-full  items-start space-y-1 py-3 px-3">
                    {" "}
                    <p className="text-xs text-gray-600 font-light ">
                      {" "}
                      USDC Value{" "}
                    </p>
                    <p className="text-lg"> $403.30</p>
                  </div>
                  <div className="flex h-full flex-col w-full items-start space-y-1   py-3 px-3">
                    {" "}
                    <p className="text-xs text-gray-600 font-light ">
                      {" "}
                      Market Cap{" "}
                    </p>
                    <p className="text-lg"> $500.4k </p>
                  </div>
                </div>
                <div className="px-4 pb-2  w-full  flex ">
                  <Line options={options} data={data} height="95%" />
                </div>
              </div> */
}
